import React from 'react';
import { HomeWrapper } from './Home.style';
import Boxes from './Boxes/Boxes';
import CarouselComponent from '../Carousel/Carousel';
import { AnimatePresence } from 'framer-motion';
import { useMediaQuery } from 'usehooks-ts'

export default function Home({setActiveSpec}) {
  const mobile = useMediaQuery('(min-width: 1200px)')

  return (
    <HomeWrapper>
      <AnimatePresence>
        {mobile ? <Boxes setActiveSpec={setActiveSpec} /> :
        <CarouselComponent setActiveSpec={setActiveSpec} />}
      </AnimatePresence>
    </HomeWrapper>
  );
}
