import React from 'react';
import { StyledFooter, Wrapper, Logo, Link, BankAccount, Mail } from './Footer.style';
import logo from '../../assets/images/small-logo.png';

export default function Footer() {
  return (
    <StyledFooter>
      <Wrapper>
        <Logo>
          <img src={logo} alt='logo fortiori' />
        <p className='Name'>FORTIORI</p>
        </Logo>
        <Mail>biuro@fortiori.pl ©{new Date().getFullYear()}</Mail>
      </Wrapper>
      <Wrapper>
        <Link target="_blank" href="/Polityka_prywatnosci.pdf">polityka prywatności</Link>
        <BankAccount><span>Nr rachunku bankowego:</span><span>PL 55 1050 1214 1000 0097 5460 2358</span></BankAccount>
      </Wrapper>
    </StyledFooter>
  );
}
