import styled from 'styled-components';
import { SIZES } from '../../assets/styles/mediaQueries';

export const HomeWrapper = styled.div`
  width: 100%;
  margin: 100px auto 0;
  position: relative;
`;

export const BannerWrapper = styled.div`
  width: 100%;
  height: 380px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-position: center;
  background-size: contain;
  z-index: 2;
  @media only screen and ${SIZES.mobileL} {
    height: 330px;
  }
  ::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 1;
  }
  a {
    display: flex;
    cursor: pointer;
    align-self: flex-end;
  }
`;

export const Content = styled.div`
display: flex;
height: 100%;
justify-content: space-between;
padding: 40px 40px;
flex-direction: column;
align-items: flex-start;
width: 100%;
margin: 0 auto;
@media only screen and ${SIZES.mobileL} {
  width: 600px;
  padding: 40px 60px;
}
`

export const AnimateWrapper = styled.div`
  z-index: 99;
  height: 66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  .top-text {
    margin-bottom: 12px;
    width: 100%;
    font-weight: 500;
    color: #ffffff;
    font-size: 18px;
    font-style: italic;
  }
  .description {
    margin-top: 10px;
    font-size: 14px;
    color: #ffffff;
    @media only screen and ${SIZES.mobileL} {
      font-size: 16px;
    }
  }
`;

export const Button = styled.button`
  font-size: 14px;
  padding: 8px 12px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid ${(props) => props.theme.grey};
  color: ${(props) => props.theme.grey};
  font-weight: 600;
  align-self: flex-start;
  z-index: 99;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    border-bottom: 2px solid ${(props) => props.theme.graphit};
  }
`