import { createGlobalStyle } from 'styled-components';
// import { SIZES } from './mediaQueries';

export const GlobalStyle = createGlobalStyle`

  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  html {
    scroll-behavior: smooth;
    height: 100vh;
  }
  body, #root{
    min-height: 100vh;
    width: 100%;
    font-family: Poppins, sans-serif;
    color: #000;
  }
  #root {
    display:flex;
    flex-direction:column;
    position: relative;
    overflow-x: hidden;
  }
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a {
    text-decoration: none;
    color: #000;
  }
  ul {
    list-style-type: none;
  }
  .disable-scroll{
    overflow: hidden;
  }
`;


