import React from 'react';
import { ElementCarousel, Wrapper } from './Carousel.style';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { specialization } from '../../constant/constants';


export default function CarouselComponent({setActiveSpec}) {

  return (
    <Wrapper>
    <Carousel infiniteLoop autoPlay interval={5000} showIndicators={false} showThumbs={false}>
      {specialization.map(({ title, img, shortDescription}) => (
        <ElementCarousel key={shortDescription}>
          <div className='img-wrapper'>
            <img className='main-img' src={img} alt='icon' />
          </div>
            <h3 className='main-title' dangerouslySetInnerHTML={{ __html: title }}></h3>
            <p className='main-description' dangerouslySetInnerHTML={{ __html: shortDescription }}></p>
            <a href='#specialization' onClick={() => setActiveSpec(title)} className='link'>Dowiedz się więcej ➔</a>
        </ElementCarousel>
      ))}
    </Carousel>
    </Wrapper>
  );
}
