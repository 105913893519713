import styled from 'styled-components';
import { SIZES } from '../../assets/styles/mediaQueries';

export const SpecializationWrapper = styled.div`
  position: relative;
  padding: 80px 24px;
  width: 100%;
  background-color: rgba(62, 62, 62, 1);
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
  .spec-title {
    text-transform: uppercase;
    font-size: 30px;
    color: #000;
  }
`;

export const Wrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  background-color: ${(props) => props.theme.graphit};
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 840px;
  padding: 0;
  @media only screen and ${SIZES.mobileL} {
    padding: 40px;
  }
`;

export const Category = styled.div`
  max-width: 800px;
  width: 100%;
  border-bottom: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  overflow: hidden;
  padding-bottom: ${(props) => props.active};
  :first-of-type {
    border-top: 1px solid #fff;
  }
  .closed {
    .vertical {
      transition: all 0.5s ease-in-out;
      transform: rotate(-90deg);
    }
    .horizontal {
      transition: all 0.5s ease-in-out;
      transform: rotate(-90deg);
      opacity: 1;
    }
  }
  .opened {
    opacity: 1;
    .vertical {
      transition: all 0.5s ease-in-out;
      transform: rotate(90deg);
    }
    .horizontal {
      transition: all 0.5s ease-in-out;
      transform: rotate(90deg);
      opacity: 0;
    }
  }
  .circle-plus {
    height: 4em;
    width: 4em;
    font-size: 1em;
    opacity: 0.7;
    position: absolute;
    right: -16px;
    top: 50%;
  }
  .circle-plus .circle {
    position: relative;
  }
  .horizontal {
    position: absolute;
    background-color: #fff;
    width: 20px;
    height: 2px;
    left: 50%;
    margin-left: -10px;
    top: 50%;
    margin-top: -1px;
  }
  .vertical {
    position: absolute;
    background-color: #fff;
    width: 2px;
    height: 20px;
    left: 50%;
    margin-left: -1px;
    top: 50%;
    margin-top: -10px;
  }
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
  color: #000;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px 0;
  transition: 0.5s;
  z-index: 2;
  padding-bottom: 20px;
  background-color: ${(props) => props.theme.graphit};
  :hover {
    color: #d7d7d7;
  }
  @media only screen and ${SIZES.tablet} {
    font-size: 20px;
  }
`;

export const ContentText = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  align-items: start;
  text-align: justify;
  hypens: auto;
  color: #fff;
  cursor: pointer;
  padding: 0 35px 0;
  overflow: hidden;
  z-index: 1;
  white-space: pre-line;
  .motto {
    margin-top: 24px;
    font-weight: 700;
  }
  @media only screen and ${SIZES.tablet} {
    font-size: 16px;
  }
`;

export const Title = styled.h1`
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 32px;
  font-size: 32px;
`;
