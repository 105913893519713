import React, { useState } from 'react';
import { BoxesWrapper, BoxToShow, TopWrapper, Box, BoxesTop, BoxesBottom, AnimateWrapper } from './Boxes.style';
import { specialization } from '../../../constant/constants';
import { motion } from 'framer-motion';

export default function Boxes({setActiveSpec}) {
  const [activeSpec, steActiveSpec] = useState(specialization[0].title);
  const [infoToShow, setInfoToShow] = useState(specialization[0]);
  return (
    <BoxesWrapper
      as={motion.div}
      initial={{ opacity: 0, y: '10%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '10%' }}
      transition={{ duration: 0.4, delay: 0.5 }}
    >
      <TopWrapper>
        <BoxToShow className='flex-center'>
          <AnimateWrapper
            key={infoToShow.title}
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <img className='main-img' src={infoToShow.img} alt={`${infoToShow.title} icon`} />
            <h2 className='main-title' dangerouslySetInnerHTML={{ __html: infoToShow.title }}></h2>
            <p className='main-description' dangerouslySetInnerHTML={{ __html: infoToShow.shortDescription }}></p>
            <a href='#specialization' onClick={() => setActiveSpec(infoToShow.title)} className='link'>Dowiedz się więcej ➔</a>
          </AnimateWrapper>
        </BoxToShow>

        <BoxesTop>
          {specialization.slice(0, 4).map((el) => (
            <Box
              key={el.title}
              className={activeSpec === el.title ? 'active flex-center box-top' : 'flex-center box-top'}
              onClick={() => {
                setInfoToShow(el);
                steActiveSpec(el.title);
              }}
            >
              <img className='box-img' src={el.img} alt={`${infoToShow.title} icon`} />
              <h2 className='box-title' dangerouslySetInnerHTML={{ __html: el.title }}></h2>
            </Box>
          ))}
        </BoxesTop>
      </TopWrapper>
      <BoxesBottom>
        {specialization.slice(4, 9).map((el) => (
          <Box
            key={el.title}
            className={activeSpec === el.title ? 'active flex-center box-bottom' : 'flex-center box-bottom'}
            onClick={() => {
              setInfoToShow(el);
              steActiveSpec(el.title);
            }}
          >
            <img className='box-img' src={el.img} alt={`${infoToShow.title} icon`} />
            <h2 className='box-title' dangerouslySetInnerHTML={{ __html: el.title }}></h2>
          </Box>
        ))}
      </BoxesBottom>
    </BoxesWrapper>
  );
}
