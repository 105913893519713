import React from 'react';
import { Wrapper, LoaderBox } from './Loader.style';

export default function Loader() {
  return (
  <Wrapper>
    <LoaderBox>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoaderBox>
  </Wrapper>
)}
