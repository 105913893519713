import React from 'react';
import { HomeWrapper, Btn } from './Maps.style';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { MarkerF } from '@react-google-maps/api';
const center = { lat: 50.262063863179606, lng: 18.991662312865994 };
export default function Maps() {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: 'AIzaSyC6rG-q8BhmWzH0caruPiprvUx1Gg4JlgA' });

  const handleNavigateClick = () => {
    const destination = `${center.lat},${center.lng}`;
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;

    window.open(googleMapsUrl, '_blank');
  };

  return (
    <HomeWrapper>
      {!isLoaded ? (
        <div>Loading...</div>
      ) : (
        <>
          <GoogleMap zoom={13} center={center} mapContainerClassName='map-container'>
            <MarkerF style={{zIndex: 10}} position={center} />
          </GoogleMap>
          <Btn onClick={handleNavigateClick}>Nawiguj</Btn>
        </>
      )}
    </HomeWrapper>
  );
}
