import styled from 'styled-components';
import { SIZES } from '../../assets/styles/mediaQueries';

export const HomeWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
  padding-bottom: 10px;
  padding-top: 50px;
  @media only screen and ${SIZES.mobileL} {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  `;
