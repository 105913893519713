import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const Box = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  img {
    width: 90px;
  }
  .open span:nth-child(1) {
    top: 12px;
    width: 0%;
    left: 50%;
  }
  .open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .open span:nth-child(4) {
    top: 12px;
    width: 0%;
    left: 50%;
  }
`;

export const MenuBtn = styled.button`
  width: 30px;
  height: 23px;
  position: relative;
  background-color: transparent;
  border: none;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: ${(props) => props.theme.graphit};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  span:nth-child(1) {
    top: 0px;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 9px;
  }
  span:nth-child(4) {
    top: 18px;
  }
`;

export const Menu = styled.ul`
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  padding-top: 40px;
  left: 100%;
  background-color: #ffffff;
  transition: 0.3s;
  border-top: 2px solid ${(props) => props.theme.gold};
  ${({ active }) =>
    active &&
    `
    left: 0;
  `}
`;

export const Logo = styled.div`
  position: relative;
  padding: 10px;
  cursor: pointer;
  img {
    width: 90px;
  }
`;

export const WrapperElement = styled.li`
  position: relative;
  padding: 30px 0;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  overflow: hidden;
`;
