import { useState } from 'react';
import { Wrapper, Box, Logo, MenuBtn, Menu, WrapperElement } from './NavBarMobile.style';
import logo from '../../../assets/images/logo-fortiori.png';

export const NavBarMobile = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const navLinks = [
    {
      title: 'zespół',
      link: '#about',
    },
    {
      title: 'specjalizacje',
      link: '#specialization',
    },
    {
      title: 'kontakt',
      link: '#contact',
    },
  ];

  return (
    <Wrapper>
      <Box>
        <Logo onClick={() => window.scrollTo(0, 0)}>
          <img src={logo} alt='logo fortiori' />
        </Logo>
        <MenuBtn onClick={() => setOpenMenu(!openMenu)} className={openMenu ? 'open' : ''}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </MenuBtn>
      </Box>
      <Menu active={openMenu}>
        {navLinks.map(({ link, title }) => (
          <WrapperElement key={title}>
            <a href={link} onClick={() => setOpenMenu(false)}>
              {title}
            </a>
          </WrapperElement>
        ))}
      </Menu>
    </Wrapper>
  );
};
