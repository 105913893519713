import React, { useEffect } from 'react';
import { SpecializationWrapper, Header, Content, Wrapper, ContentText, Category, Title } from './Specialization.style';
import { specialization } from '../../constant/constants';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const boxVariant = {
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  hidden: { opacity: 0, y: '10%' },
};

export default function Specialization({ activeSpec, setActiveSpec }) {
  const [ref, inView] = useInView();
  const control = useAnimation();

  useEffect(() => {
    if (inView) {
      control.start('visible');
    }
  }, [control, inView]);

  return (
    <SpecializationWrapper id='specialization'>
      <Wrapper>
        <Content as={motion.div} ref={ref} variants={boxVariant} initial='hidden' animate={control}>
          <Title>Specjalizacje</Title>
          {specialization.map(({ longDescription, title }) => (
            <Category
              key={title}
              active={activeSpec === title ? '20px' : '0px'}
              className={activeSpec === title ? 'active' : ''}
              onClick={() => (activeSpec === title ? setActiveSpec('') : setActiveSpec(title))}
            >
              <Header className={activeSpec === title ? 'active' : ''}>
                <p dangerouslySetInnerHTML={{ __html: title }}></p>
                <div className={activeSpec === title ? 'circle-plus opened' : 'circle-plus closed'}>
                  <div className='circle'>
                    <div className='horizontal'></div>
                    <div className='vertical'></div>
                  </div>
                </div>
              </Header>
              <AnimatePresence>
                {activeSpec === title && (
                  <ContentText
                    as={motion.div}
                    initial={{ top: '-100%', height: 0 }}
                    animate={{ top: 0, height: 'fit-content' }}
                    exit={{ top: '-100%', height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <p dangerouslySetInnerHTML={{ __html: longDescription[0] }}></p>
                    <p className='motto' dangerouslySetInnerHTML={{ __html: longDescription[1] }}></p>
                  </ContentText>
                )}
              </AnimatePresence>
            </Category>
          ))}
        </Content>
      </Wrapper>
    </SpecializationWrapper>
  );
}
