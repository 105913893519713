import styled from 'styled-components';

export const StyledNavBar = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  padding: 0 30px;
  background-color: #fff;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const NavWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
`;

export const WrapperRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const WrapperNav = styled.ul`
  display: flex;
  justify-content: center;
`;

export const WrapperElement = styled.li`
  position: relative;
  padding: 30px 0;
  text-transform: uppercase;
  font-size: 22px;
  margin-left: 30px;
  font-weight: 500;
  overflow: hidden;
  ::before {
    content: '';
    position: absolute;
    bottom: 30px;
    left: -100%;
    width: 100%;
    height: 3px;
    background-color: ${(props) => props.theme.gold};
    transition: 0.4s;
  }
  :hover::before,
  .active::before {
    left: 0;
  }
`;

export const Logo = styled.div`
  position: relative;
  padding: 10px;
  cursor: pointer;
  img {
    width: 90px;
  }
`;
