import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import background from '../../assets/images/bck3.jpg';
import click from '../../assets/images/click.png';
import {
  AboutWrapper,
  TopRow,
  Title,
  WrapperList,
  Card,
  Name,
  Role,
  Email,
  BioWrapper,
  BioBox,
  Top,
  DataBio,
  Text,
  ImgWrapper,
} from './About.style';
import { team } from '../../constant/constants';

const boxVariant = {
  visible: { opacity: 1, transition: { duration: 1, delay: 0.3 } },
  hidden: { opacity: 0 },
};

export default function About() {
  const [openBio, setOpenBio] = useState(false);
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (openBio) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }
  }, [openBio]);

  useEffect(() => {
    if (inView) {
      control.start('visible');
    }
  }, [control, inView]);

  return (
    <AnimatePresence>
      <AboutWrapper id='about'>
        <div className='background-shadow'>
          <img className='background' src={background} alt='katowice miasto' />
        </div>
        <TopRow as={motion.div} ref={ref} variants={boxVariant} initial='hidden' animate={control}>
          <Title>zespół</Title>
          <WrapperList>
            {team.map((item) => (
              <div key={item.name}>
                <Card>
                  <div className='img-wrapper'>
                    <img src={item.img} alt={item.name} className='img-card' onClick={() => setOpenBio(true)} />
                    <img src={click} alt='info' className='icon-click' onClick={() => setOpenBio(true)} />
                  </div>
                  <Name>{item.name}</Name>
                  <Role>{item.title}</Role>
                  <Email href='#contact'>{item.email}</Email>
                </Card>
                {openBio && (
                  <BioWrapper onClick={() => setOpenBio(false)}>
                    <BioBox>
                      <Top>
                        <img src={item.img} alt={item.name} className='img-bio' />
                        <DataBio>
                          <Name>{item.name}</Name>
                          <Role>{item.title}</Role>
                          <Email href='#contact'>{item.email}</Email>
                        </DataBio>
                      </Top>
                      {item.bio.map((text) => (
                        <Text dangerouslySetInnerHTML={{ __html: text }}></Text>
                      ))}
                    </BioBox>
                  </BioWrapper>
                )}
              </div>
            ))}
          </WrapperList>
        </TopRow>
      </AboutWrapper>
    </AnimatePresence>
  );
}
