export const SIZES = {
  mobileS: `(min-width: 360px)`,
  mobileM: `(min-width: 414px)`,
  mobileL: `(min-width: 576px)`,
  tablet: `(min-width: 768px)`,
  laptop: `(min-width: 1024px)`,
  laptopM: `(min-width: 1200px)`,
  laptopL: `(min-width: 1400px)`,
  desktop: `(min-width: 1680px)`,
  desktopL: `(min-width: 2000px)`,
}
