import styled from 'styled-components';
import { SIZES } from '../../assets/styles/mediaQueries';

export const AboutWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 400px;
  padding: 0 24px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .background-shadow {
    width: 100vw;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    ::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.6;
    }
  }
  .background {
    height: 100vh;
    width: auto;
    @media only screen and ${SIZES.laptopM} {
      object-fit: cover;
      width: 100vw;
    }
  }
  .img-bio {
    width: 150px;
    height: 220px;
    object-fit: cover;
  }
`;
export const TopRow = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  margin-top: 100px;
  align-items: center;
`;

export const Title = styled.h2`
width: 100%;
font-size: 38px;
color: ${(props) => props.theme.gold};
font-weight: 500;
text-transform: uppercase;
text-align: center;
`;

export const WrapperList = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
`;

export const Card = styled.div`
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  
  padding: 30px 30px;
  position: relative;
  .img-wrapper {
    cursor: pointer;
  }
  .img-card {
    width: 200px;
    height: 290px;
    object-fit: cover;
  }
  .icon-click {
    position: absolute;
    right: 7px;
    top: 7px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
`;

export const Name = styled.p`
  text-transfrom: uppercase;
  font-weight: 600;
`;
export const Role = styled.p`
  text-transfrom: uppercase;
  font-weight: 400;
  border-bottom: 1px solid black;
  padding-bottom: 14px;
  margin-bottom: 8px;
  `;
export const Email = styled.a`
  font-size: 14px;
  transition: 0.3s;
  :hover {
    opacity: 0.8;
  }
`;
export const BioWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BioBox = styled.div`
  max-width: 700px;
  max-height: 90vh;
  margin: 40px;
  width: 100%;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  
  padding: 20px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
   
   
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 40px;
  }
  @media only screen and ${SIZES.mobileL} {
    padding: 40px;
  }
`;
export const Top = styled.div`
  display: flex; 
  width: 100%;
  align-items: center;
  column-gap: 40px;
  margin-bottom: 40px;
  flex-direction: column;
  img {
    cursor: pointer;
    width: 140px;
  }
  @media only screen and ${SIZES.mobileL} {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }
`;
export const DataBio = styled.div`
  display: flex; 
  flex-direction: column;
  justify-content: center;
`;
export const Text = styled.p`
  font-size: 14px;
  text-align: justify;
  margin-bottom: 10px;
`;
