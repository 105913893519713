import styled from 'styled-components';

export const HomeWrapper = styled.div`
  width: 100%;
  height: 50vh;
  background-color: #fff;
  position: relative;
  .map-container {
    width: 100%;
    height: 50vh;
    filter: grayscale(1) brightness(0.8);
  }
`;

export const Btn = styled.button`
  width: 110px;
  font-size: 18px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 6px 10px;
  background-color: ${props => props.theme.graphit};
  color: #fff;
  border-style: none;
  cursor: pointer;
`;

