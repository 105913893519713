import styled from 'styled-components';
import { SIZES } from '../../assets/styles/mediaQueries';

export const FormWrapper = styled.div`
  padding-top: 100px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  `;

export const Section = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 60px 0 100px;
  row-gap: 60px;
  padding: 0 20px;
`;

export const WrapperData = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media only screen and ${SIZES.tablet} {
    flex-direction: column;
    margin-top: 40px;
  }
  @media only screen and ${SIZES.laptopM} {
    flex-direction: row;
    margin-top: 40px;
  }
  .icon { 
    top: 20px;
    position: absolute;
    font-size: 34px;
    color: ${(props) => props.theme.graphit};
  }
`;


export const Contact = styled.div`
  position: relative;
  max-width: 400px;
  width: 100%;
  height: 220px;
  padding: 50px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: 0.3s;
  :hover {
    transform: scale(1.02);
  }
  @media only screen and ${SIZES.tablet} {
    font-size: 16px;
  }
`;

export const Data = styled.form`
  position: relative;
  max-width: 400px;
  width: 100%;
  height: 220px;
  padding: 50px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  
  transition: 0.3s;
  :hover {
    transform: scale(1.02);
  }
  @media only screen and ${SIZES.tablet} {
    font-size: 16px;
  }
`;

export const SectionTitle = styled.h2`
  width: 100%;
  font-size: 38px;
  color: ${(props) => props.theme.gold};
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
`;

export const Content = styled.div`
  font-weight: 400;
  margin-top: 6px;
  font-weight: 500;
`;
