import styled from 'styled-components';
import { SIZES } from '../../assets/styles/mediaQueries';

export const ElementCarousel = styled.div`
  height: 400px;
  padding: 20px 20px;
  margin: 0 20px 60px;
  background-color: #fff;
  border: 4px solid ${(props) => props.theme.graphit};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #000;
  .img-wrapper {
    height: 80px;
    width: 80px;
    @media only screen and ${SIZES.tablet} {
      height: 100px;
      width: 100px;
    }
  }
  .main-title {
    margin: 20px 0;
    text-transform: uppercase;
    font-size: 18px;
    @media only screen and ${SIZES.tablet} {
      font-size: 24px;
    }
  }
  .main-description {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    @media only screen and ${SIZES.tablet} {
      font-size: 18px;
    }
  }
  .link {
    position: relative;
    margin-top: 10px;
    font-size: 12px;
    margin-left: 4px;
    font-weight: 700;
    color: ${(props) => props.theme.graphit};
    border-bottom: 2px solid ${(props) => props.theme.graphit};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 24px;
  margin: 60px auto;
  .carousel-status {
    display: none;
  }
  @media only screen and ${SIZES.mobileL} {
    margin: 120px auto;
  }
`
