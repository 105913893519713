import React, { useEffect } from 'react';
import { FormWrapper, WrapperData, Contact, Data, Content, Section, SectionTitle } from './Contact.style';
import Form from '../Form/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const boxVariantData = {
  visible: { opacity: 1, transition: { duration: 0.7, delay: 0.3 } },
  hidden: { opacity: 0 },
};

export default function ContactSection() {
  const control = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      control.start('visible');
    }
  }, [control, inView]);

  return (
    <AnimatePresence>
      <FormWrapper id='contact'>
        <SectionTitle>
          kontakt
        </SectionTitle>
        <Section>
          <Form />
          <WrapperData as={motion.div} ref={ref} variants={boxVariantData} initial='hidden' animate={control}>
            <Contact>
              <FontAwesomeIcon className='icon' icon={faAddressCard} />
              <Content>biuro@fortiori.pl</Content>
              <Content>+48 799 904 600</Content>
            </Contact>
            <Data>
              <FontAwesomeIcon className='icon' icon={faBriefcase} />
                <Content>Kancelaria Radcy Prawnego</Content>
                <Content> Michał Wręczycki</Content>
              <Content>NIP: 6343009908</Content>
              <Content>REGON: 522055310</Content>
            </Data>
            <Data>
              <FontAwesomeIcon className='icon' icon={faLocationDot} />
              <Content>ul. Pukowca 15, bud. “B”, II. piętro, </Content>
              <Content>40-847 Katowice</Content>
            </Data>
          </WrapperData>
        </Section>
      </FormWrapper>
    </AnimatePresence>
  );
}
