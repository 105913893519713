import styled from 'styled-components';

export const SwitcherWrapper = styled.div`
  margin-left: 20px;
`

export const Switcher = styled.select`
  background-color: transparent;
  border: none;
  color: #000;
  font-weight: 700;
  font-size: 16px;
`
