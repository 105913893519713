import styled from 'styled-components';

export const BoxesWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 120px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .box-top {
    width: calc(50% - 4px);
  }
  .box-bottom {
    width: 20%;
  }
  .active {
    background-color: rgba(62, 62, 62, 0.9);
  }
`;

export const BoxToShow = styled.div`
  width: 60%;
  height: 400px;
  margin-left: 4px;
  padding: 20px 40px;
  background-color: #fff;
  border: 4px solid ${(props) => props.theme.graphit};
`;

export const AnimateWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #000;
  .main-img {
    height: 100px;
    width: 100px;
  }
  .main-title {
    margin: 20px 0;
    text-transform: uppercase;
  }
  .main-description {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
  }
  .link {
    position: relative;
    margin-top: 10px;
    font-size: 12px;
    margin-left: 4px;
    font-weight: 700;
    color: ${(props) => props.theme.graphit};
    border-bottom: 2px solid ${(props) => props.theme.graphit};
  }
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const BoxesTop = styled.div`
  width: 40%;
  display: flex;
  flex-wrap: wrap;
`;

export const Box = styled.div`
  height: 198px;
  flex-direction: column;
  background-color: ${(props) => props.theme.graphit};
  margin-left: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    background-color: rgba(62, 62, 62, 0.9);
  }
  padding: 0 20px;
  .box-img {
    width: 60px;
  }
  .box-title {
    font-size: 15px;
    color: #fff;
    margin-top: 10px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const BoxesBottom = styled.div`
  width: 100%;
  display: flex;
`;
