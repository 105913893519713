import styled from 'styled-components';
import { SIZES } from '../../assets/styles/mediaQueries';

export const FormWrapper = styled.form`
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  row-gap: 32px;
`;

export const InputWrapper = styled.div`
  align-items: 'start';
  row-gap: 16px;
  width: 100%;
  position: relative;
  ${({ noAnimation }) =>
    !noAnimation &&
    `
  &:after {
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 0px;
    background: transparent;
    transition: width 0.5s ease, background-color 0.5s ease;
  }
  &:focus-within {
    &:after {
      width: 100%;
      background: #A75F48;
    }
  }
`}
  label {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    cursor: url('/cursor.svg'), auto;
    :nth-of-type(2) {
      margin-bottom: 20px;
    }
  }
  input,
  select {
    width: 100%;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.graphit};
    padding-bottom: 8px;
    line-height: 200%;
    background: inherit;
    margin-bottom: -1px;
  }
  select:focus {
    border-bottom: 0.5px solid #a75f48;
  }
  input:focus-visible,
  select:focus {
    outline: none;
  }
  .submitBtn {
    width: 250px;
    justify-content: center;
    align-self: center;
    &:disabled {
      color: #777;
    }
  }
  .subtext {
    font-size: 13px;
    color: #444;
  }
  .upload {
    font-size: 14px;
    font-weight: 400;
    line-height: 200%;
    cursor: url('/cursor.svg'), auto;
  }
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 30px;
  @media only screen and ${SIZES.tablet} {
    column-gap: 60px;
  }
`;

export const TextareaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  textarea {
    padding: 10px;
    width: 100%;
    height: 100px;
  }
  textarea:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.gold};
  }
  label {
    width: 100%;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    cursor: url('/cursor.svg'), auto;
    margin-bottom: 12px;
  }
`;

export const SubmitBtn = styled.button`
  margin-top: 20px;
  padding: 8px 24px;
  font-size: 18px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border: none;
  color: #000;
  border-bottom: 3px solid ${(props) => props.theme.graphit};
  cursor: pointer;
  :disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

export const Error = styled.p`
  position: absolute;
  font-size: 12px;
  color: #b3050b;
`;

export const AcceptWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  input[type='checkbox'] {
    accent-color: ${(props) => props.theme.graphit};
    width: 16px;
    height: 16px;
  }
`;

export const CheckboxText = styled.label`
  font-size: 12px;
  color: ${(props) => props.theme.graphit};
  display: inline;
  width: calc(100% - 34px);
  .link {
    color: ${(props) => props.theme.graphit};
    text-decoration: underline;
    display: inline;
    white-space: nowrap;
  }
`;
