import kafel1 from '../assets/images/kafel1.png';
import kafel2 from '../assets/images/kafel2.png';
import kafel3 from '../assets/images/kafel3.png';
import kafel4 from '../assets/images/kafel4.png';
import kafel5 from '../assets/images/kafel5.png';
import kafel6 from '../assets/images/kafel6.png';
import kafel7 from '../assets/images/kafel7.png';
import kafel8 from '../assets/images/kafel8.png';
import kafel9 from '../assets/images/kafel9.png';
import michalWreczycki from '../assets/images/team/michal-wreczycki.png';

export const specialization = [
  {
    img: kafel3,
    title: 'Pomoc frankowiczom',
    shortDescription: 'Analiza umów kredytów i&nbsp;pożyczek; doradztwo i&nbsp;reprezentacja w&nbsp;sporze z&nbsp;bankiem.',
    longDescription:
      ["Kompleksowo analizujemy umowy kredytów i&nbsp;pożyczek zależnych od walut obcych (CHF, EUR, USD, JPY); przedstawiamy autorskie i&nbsp;indywidualnie opracowane matematyczne wyliczenie potencjalnych korzyści w&nbsp;sporze z&nbsp;bankiem. Od lat z&nbsp;sukcesami reprezentujemy Klientów przed sądami powszechnymi wszystkich instancji oraz Sądem Najwyższym w&nbsp;dochodzeniu roszczeń od kredytodawców.","Eliminujemy z obrotu umowy, które narażały kredytobiorców na nieograniczone ryzyko i&nbsp;powodowały nieuzasadnione zawyżanie salda zadłużenia.",]
  },
  {
    img: kafel6,
    title: 'Postępowanie sądowe',
    shortDescription: 'Sporządzanie pozwów, wniosków, apelacji, zażaleń – na każdym etapie postępowania.',
    longDescription: [
      'Udzielamy porad w&nbsp;zakresie zasadności wszczęcia i&nbsp;prowadzenia postępowania sądowego dotyczącego sprawy o&nbsp;przedstawionej specyfice, przygotowujemy wszelkie pisma procesowe na każdym etapie postępowania, zapewniamy fachową reprezentację przed sądami powszechnymi wszystkich instancji oraz przed Sądem Najwyższym i&nbsp;Naczelnym Sądem Administracyjnym.',
      'Nie wahamy się, gdy należy zwrócić się o&nbsp;rozstrzygnięcie do sądu i&nbsp;skutecznie podejmujemy wszelkie możliwe kroki celem uzyskania pozytywnego rozstrzygnięcia.',
    ],
  },
  {
    img: kafel5,
    title: 'Upadłość',
    shortDescription: 'Przygotowywanie wniosków o&nbsp;ogłoszenie upadłości; reprezentacja w&nbsp;postępowaniu upadłościowym.',
    longDescription: [
      'Analizujemy sytuację finansową dłużnika pod kątem wystąpienia stanu niewypłacalności oraz stopnia zadłużenia; przygotowujemy wniosek o&nbsp;ogłoszenie upadłości wraz z&nbsp;wymaganymi załącznikami. Reprezentujemy zarówno w&nbsp;postępowaniu w&nbsp;przedmiocie ogłoszenia upadłości, jak i&nbsp;we właściwym postępowaniu upadłościowym (z&nbsp;udziałem syndyka).',
      'Zajmując się wszelkimi formalnymi aspektami postępowania, umożliwiamy zadłużonym uzyskanie umorzenia wszelkich zaciągniętych zobowiązań.',
    ],
  },
  {
    img: kafel4,
    title: 'Restrukturyzacja',
    shortDescription: 'Doradztwo w&nbsp;zakresie przygotowania, wdrożenia i&nbsp;realizacji planu restrukturyzacyjnego.',
    longDescription: [
      'Weryfikujemy dokumentację oraz analizujemy stopień niewypłacalności. Doradzamy, w&nbsp;jaki sposób przeprowadzić skuteczną restrukturyzację przedsiębiorstwa, która okaże się optymalnym sposobem regulacji zobowiązań i&nbsp;pozwoli na dalsze prowadzenie działalności. Proponujemy warunki układu z&nbsp;wierzycielami. Przygotowujemy i&nbsp;opiniujemy plany restrukturyzacyjne, opracowujemy pisma procesowe oraz reprezentujemy przed sądem.',
      'Działamy po to, by każda działalność mogła mieć swoją kolejną szansę na optymalny rozwój.',
    ],
  },
  {
    img: kafel2,
    title: 'Przygotowywanie i&nbsp;opiniowanie umów',
    shortDescription: 'Sporządzenie i&nbsp;opiniowanie umów; konstruowanie dokumentów dotyczących ładu korporacyjnego.',
    longDescription: [
      'Sporządzamy umowy, porozumienia, regulaminy czy dokumenty korporacyjne o&nbsp;każdym charakterze występującym w obrocie – w sposób jak najdalej zabezpieczający interes Klienta. Opiniujemy przedstawione dokumenty, proponując zmiany i&nbsp;wskazując słabe ich punkty, prezentując pisemną opinię prawną lub też gotowy projekt zmian.',
      'Kreujemy rozwiązania szyte na miarę.',
    ],
  },
  {
    img: kafel1,
    title: 'Windykacja i&nbsp;antywindykacja',
    shortDescription: 'Ściąganie wierzytelności lub ochrona prawna przed egzekucją.',
    longDescription: [
      'Pomagamy wierzycielom w&nbsp;zakresie ściągania niezaspokojonych długów; kierujemy wezwania do zapłaty, wykonania umów czy też zwrotu rzeczy. Na rzecz zadłużonych świadczymy kompleksową pomoc w&nbsp;zakresie ochrony przed egzekucją i&nbsp;uporczywą windykacją.',
      'Wierzycielom niesiemy pomoc w zaspokojeniu ich słusznych interesów. Dłużników wspieramy, chroniąc ich majątek przed nieuprawnioną ingerencją.',
    ],
  },
  {
    img: kafel9,
    title: 'Prawo medyczne',
    shortDescription:
      'Dochodzenie odszkodowania i&nbsp;zadośćuczynienia za błąd w&nbsp;sztuce lekarskiej, pozyskiwanie dokumentacji medycznej, reprezentacja przed Rzecznikiem Praw Pacjenta.',
    longDescription: [
      'Doradzamy i&nbsp;prowadzimy postępowania w&nbsp;przedmiocie odpowiedzialności placówek medycznych za popełnione błędy w sztuce lekarskiej, uzyskując dla pokrzywdzonych pacjentów zadośćuczynienie i&nbsp;odszkodowanie. Klientów reprezentujemy także przed Rzecznikiem Praw Pacjenta.',
      'Podejmujemy wszelkie kroki, by osoby cierpiące z&nbsp;powodu popełnionych na ich szkodę błędów medycznych mogły w&nbsp;jak najwyższym stopniu uzyskać stosowną rekompensatę.',
    ],
  },
  {
    img: kafel8,
    title: 'IT i&nbsp;nowe technologie',
    shortDescription:
      'Obsługa podmiotów z&nbsp;sektorów NT i&nbsp;IT. Ochrona danych osobowych, kontrakty IT, prawa autorskie, tajemnica przedsiębiorstwa.',
    longDescription: [
      'Wraz z&nbsp;dynamicznym rozwojem branż informatycznych i&nbsp;nowych technologii, dopasowujemy równie nowoczesne rozwiązania prawne. Działamy w&nbsp;duchu agile, świadcząc usługi indywidualnie stworzone dla danego przedsięwzięcia.',
      'Twój projekt jest także i&nbsp;naszym projektem.',
    ],
  },
  {
    img: kafel7,
    title: 'Prawo budowlane',
    shortDescription:
      'Obsługa inwestycji budowlanych, dochodzenie roszczeń z&nbsp;tytułu rękojmi za wady nieruchomości, analiza przedsięwzięć deweloperskich.',
    longDescription: [
      'Sporządzamy i&nbsp;opiniujemy umowy o&nbsp;roboty budowlane oraz umowy deweloperskie, obsługujemy inwestycje od przejęcia placu budowy do podpisania protokołu odbioru końcowego, pomagamy przy rozwiązaniu sporów dotyczących terminów, jakość prac, robót dodatkowych, kar umownych i&nbsp;ewentualnego odstąpienia od umowy; w&nbsp;tym także prowadząc postępowania na etapie sądowym.',
      'Stanowimy pewny fundament prawny Twojej inwestycji.',
    ],
  },
];

export const team = [
  {
    img: michalWreczycki,
    name: 'MICHAŁ WRĘCZYCKI',
    title: 'RADCA PRAWNY',
    email: 'michal.wreczycki@fortiori.pl',
    bio: [
      'Radca prawny wpisany na listę przy Okręgowej Izbie Radców Prawnych w&nbsp;Katowicach.',
      'W&nbsp;trybie stacjonarnym ukończył prawo na Wydziale Prawa i&nbsp;Administracji Uniwersytetu Śląskiego w Katowicach, broniąc pracy magisterskiej z&nbsp;zakresu ochrony konsumentów jako kredytobiorców kredytów wyrażonych w&nbsp;walutach obcych.',
      'Nominowany w&nbsp;plebiscycie na najlepszą pracę magisterską z&nbsp;zakresu ochrony konsumentów organizowanego przez Prezesa Urzędu Ochrony Konkurencji i&nbsp;Konsumentów.',
      'Doświadczenie zawodowe zdobywał w&nbsp;wielu instytucjach i&nbsp;organach administracji publicznej, a&nbsp;także świadcząc usługi na rzecz wiodących śląskich oraz warszawskich kancelarii prawnych.',
      'Nie zasiada w&nbsp;kancelarii za ciężkim dębowym biurkiem z&nbsp;rustykalną zieloną lampą. Nie prowadzi też branżowego bloga. Skupia się jedynie na tym, by świadczyć usługi na najwyższym poziomie i&nbsp;w&nbsp;sposób dopasowany do potrzeb Klienta. Rozwiązania dopasowuje do indywidualnych oczekiwań, a&nbsp;współpracę kreuje na elastycznych zasadach.',
      'W&nbsp;wolnym czasie oddaje się lekturze polskiej i&nbsp;zagranicznej fantastyki, z&nbsp;uporem pokonuje kolejne kilometry półmaratonów oraz wytrwale kibicuje jednemu z&nbsp;londyńskich klubów piłkarskich.'
    ]
  }
];
