import styled from 'styled-components';
import { SIZES } from '../../assets/styles/mediaQueries';

export const StyledFooter = styled.footer`
  width: 100%;
  background-color: ${props => props.theme.graphit};
  border-top: 1px solid #fff;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: #fff;
  row-gap: 10px;
  flex-direction: column;
  @media only screen and ${SIZES.laptopM} {
    flex-direction: row;
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media only screen and ${SIZES.mobileL} {
    flex-direction: row;
  }
`

export const Logo = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  padding-right: 10px;
  border-right: 1px solid #fff;
  img {
    height: 35px;
  }
`

export const Link = styled.a`
  font-size: 10px;
  color: #fff;
  padding-right: 10px;
  cursor: pointer;
  white-space: nowrap;
  border-right: 1px solid #fff;
  @media only screen and ${SIZES.mobileL} {
    font-size: 12px;
  }
`

export const BankAccount = styled.p`
  font-size: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  column-gap: 4px;
  align-items: start;
  @media only screen and ${SIZES.tablet} {
    flex-direction: row;
  }
  @media only screen and ${SIZES.mobileL} {
    font-size: 12px;
    align-items: start;
  }
`

export const Mail = styled.p`
  font-size: 12px;
  color: #fff;
  @media only screen and ${SIZES.mobileL} {
    font-size: 14px;
  }
`