import React, { useState } from 'react';
import { HomeWrapper, BannerWrapper, AnimateWrapper, Button, Content } from './Baner.style';
import { AnimatePresence, motion } from 'framer-motion';
import 'react-multi-carousel/lib/styles.css';
import { useEffect } from 'react';

export default function Baner() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (index === 2) {
        setIndex(0);
      } else if (index === 1) {
        setIndex(2);
      } else if (index === 0) {
        setIndex(1);
      }
    }, 9000);

    return () => clearInterval(interval);
  }, [index]);

  const data = [
    {
      top: '“Kto mówi językiem niezrozumiałym dla nikogo poza nim, nie mówi w ogóle. Mówić, to mówić do kogoś.”',
      subtitle:
        'W sposób klarowny objaśniamy nawet najbardziej skomplikowane zagadnienia. Wystrzegamy się prawniczej nowomowy. ',
    },
    {
      top: '„Argumentów nie należy liczyć, lecz ważyć.”',
      subtitle: 'Spośród wszelkich prawnych narzędzi dobieramy te, dzięki którym najskuteczniej osiągniemy sukces.',
    },
    {
      top: '„Jak niewiele dostrzegają Ci, którzy dokonują pochopnych ocen.”',
      subtitle:
        'Rekomendujemy tylko bezpieczne i pewne rozwiązania. Kompleksowo chronimy Klientów przed wszelkim możliwym ryzykiem.',
    },
    {
      top: '„Jeden człowiek, który ma odwagę, to większość.”',
      subtitle: 'Znajdujemy drogę tam, gdzie inni postanowili zawrócić. Determinacja jest naszym atutem.',
    },
  ];

  return (
    <HomeWrapper>
      <BannerWrapper>
        <Content>
          <AnimatePresence exitBeforeEnter>
            <AnimateWrapper
              key={index}
              as={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1.5 }}
            >
              <p className='top-text'>{data[index].top}</p>
              <p className='description'>{data[index].subtitle}</p>
            </AnimateWrapper>
          </AnimatePresence>
          <a href='#contact'>
            <Button>Dowiedz się więcej</Button>
          </a>
        </Content>
      </BannerWrapper>
    </HomeWrapper>
  );
}
