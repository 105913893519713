import { useState } from 'react'
import NavBar from './components/NavBar/NavBar'
import Home from './components/Home/Home'
import About from './components/About/About'
import ContactSection from './components/Contact/Contact'
import MapsComponent from './components/Maps/Maps'
import Baner from './components/Baner/Baner'
import Footer from './components/Footer/Footer'
import Specialization from './components/Specialization/Specialization'
import CookieConsent from "react-cookie-consent";

function App() {
  const [activeSpec, setActiveSpec] = useState('')

  return (
    <div>
      <NavBar />
      <Baner />
      <Home setActiveSpec={setActiveSpec} />
      <About />
      <Specialization activeSpec={activeSpec} setActiveSpec={setActiveSpec} />
      <ContactSection />
      <MapsComponent />
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Zaakceptuj"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#A15F48", color: "#ffffff", paddingLeft: 20, paddingRight: 20, opacity: 1 }}
        buttonStyle={{ backgroundColor: "transparent", fontSize: "16px", color: "#ffffff", padding: "6px 10px", fontWeight: 500, borderBottom: "1px solid #fff" }}
        expires={150}
      >
        Ta strona korzysta z plików cookie, aby poprawić komfort użytkownika.
        <a style={{color: '#ffffff', opacity: 0.8, paddingLeft: '8px', fontSize: '14px', whiteSpace: 'nowrap'}} target="_blank" href="/Polityka_prywatnosci.pdf">Więcej informacji</a>
      </CookieConsent>
    </div>
    
  );
}

export default App;
