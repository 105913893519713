import React, { useEffect, useState } from 'react';
import { specialization } from '../../constant/constants';
import {
  FormWrapper,
  InputWrapper,
  Error,
  TextareaWrapper,
  SubmitBtn,
  AcceptWrapper,
  CheckboxText,
} from './Form.style';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ReCAPTCHA from 'react-google-recaptcha';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import Loader from '../Loader/Loader';

const boxVariantForm = {
  visible: { opacity: 1, transition: { duration: 0.7, delay: 0.8 } },
  hidden: { opacity: 0 },
};

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export default function Form() {
  const [email, setEmail] = useState('');
  const [topic, setTopic] = useState('');
  const [description, setDescritpion] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const notifySuccess = () => toast.success('Wiadomość została wysłana.');
  const notifyFailed = () =>
    toast.success('Wysyłanie wiadomości zaklończone niepowodzeniem.Proszę spróbuj jeszcze raz.');

  const control = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      control.start('visible');
    }
  }, [control, inView]);

  useEffect(() => {
    if (email && topic && description) {
      if (emailRegex.test(email)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    } else {
      setEmailError(false);
    }
  }, [email, topic, description]);

  const handleForm = (e) => {
    setLoading(true);
    axios
      .post(
        'https://formspree.io/f/mwkwpvyo',
        {
          mail: email,
          temat: topic,
          treść: description,
        },
        { headers: { Accept: 'application/json' } }
      )
      .then(function (response) {
        notifySuccess();
        setEmail('');
        setDescritpion('');
        setTopic('');
        setLoading(false);
      })
      .catch(function (error) {
        notifyFailed();
      });
  };

  return (
    <FormWrapper as={motion.div} ref={ref} variants={boxVariantForm} initial='hidden' animate={control}>
      <Toaster />
      <InputWrapper>
        <label>Twój e-mail:</label>
        <input id='email' type='email' value={email} onChange={(e) => setEmail(e.target.value)} name='email' />
        {emailError && <Error>Nieprawidłowy e-mail</Error>}
      </InputWrapper>
      <InputWrapper>
        <label>Wybierz temat:</label>
        <select id='topic' type='text' name='topic' value={topic} onChange={(e) => setTopic(e.target.value)}>
          <option>Wybierz</option>
          {specialization.map(({ title }) => (
            <option key={title} dangerouslySetInnerHTML={{ __html: title }}></option>
          ))}
          <option>Inny</option>
        </select>
      </InputWrapper>
      <TextareaWrapper>
        <label>Opisz swoją sprawę:</label>
        <textarea id='message' value={description} name='message' onChange={(e) => setDescritpion(e.target.value)} />
      </TextareaWrapper>
      <AcceptWrapper>
        <input id='terms' type='checkbox' checked={acceptTerms} onChange={() => setAcceptTerms(!acceptTerms)} />
        <CheckboxText htmlFor='terms'>
          <span>Oświadczam, że zapoznałem się z&nbsp;</span>
          <a className='link' href='/Polityka_prywatnosci.pdf' target='_blank'>
            Polityką Prywatności
          </a>
          <span>&nbsp;portalu, w tym klauzulą przetwarzania danych osobowych oraz akceptuję ich treść.</span>
        </CheckboxText>
      </AcceptWrapper>
      <ReCAPTCHA sitekey='6Lf-JtQnAAAAAHJ41j64CU_K7Lv3aF6yOdek_hyT' onChange={(value) => setCaptcha(value)} />
      <SubmitBtn
        disabled={!captcha || !description || !topic || !emailRegex.test(email) || topic === 'Wybierz' || !acceptTerms}
        onClick={() => handleForm()}
      >
        Wyślij
      </SubmitBtn>
      {loading && <Loader />}
    </FormWrapper>
  );
}
