import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { StyledNavBar, WrapperElement, WrapperNav, Logo, WrapperRight, NavWrapper } from './NavBar.style';
import logo from '../../assets/images/logo-fortiori.png';
import LanguageSwitcher from './LanguageSwitcher/LanguageSwitcher';
import { motion, AnimatePresence } from 'framer-motion';
import { NavBarMobile } from './NavBarMobile/NavBarMobile';

export default function NavBar() {
  const mobile = useMediaQuery('(max-width: 768px)');
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    typeof window !== 'undefined' && setIsMobile(mobile);
  }, [mobile]);

  const navLinks = [
    {
      title: 'zespół',
      link: '#about',
    },
    {
      title: 'specjalizacje',
      link: '#specialization',
    },
    {
      title: 'kontakt',
      link: '#contact',
    },
  ];

  return isMobile ? (
    <NavBarMobile />
  ) : (
    <AnimatePresence>
      <StyledNavBar>
        <NavWrapper
          as={motion.div}
          initial={{ opacity: 0, y: '-30%' }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: '-30%' }}
          transition={{ duration: 0.5 }}
        >
          <Logo onClick={() => window.scrollTo(0, 0)}>
            <img src={logo} alt='logo fortiori' />
          </Logo>
          <WrapperRight>
            <WrapperNav>
              {navLinks.map(({ link, title }) => (
                <WrapperElement key={title}>
                  <a href={link}>{title}</a>
                </WrapperElement>
              ))}
            </WrapperNav>
            {/* <LanguageSwitcher /> */}
          </WrapperRight>
        </NavWrapper>
      </StyledNavBar>
    </AnimatePresence>
  );
}
